// Trick VH for mobile Devices
let vh = window.innerHeight * 0.01;

let setVHSize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

document.addEventListener('DOMContentLoaded', setVHSize);
window.addEventListener('resize', setVHSize);

// Scroll-Detection
document.addEventListener('scroll', () => {
  if (window.scrollY > 0 ) {
    document.body.classList.add('body--scrolled')
  } else {
    document.body.classList.remove('body--scrolled')
  }
})

// Navigation
document.querySelector('.headerbar__navtoggler').addEventListener('click', () => {
  if (window.innerWidth < 992) {
    document.body.classList.toggle('body--activenav');
  }
});

// Dropdown Arrow Positioning

document.addEventListener('DOMContentLoaded', () => {
  let firstLevelNavigationCount = document.querySelectorAll('.headerbar__navigation > ul >li').length - 1;
  document.documentElement.style.setProperty('--firstLevelNavigationChildCount', `${firstLevelNavigationCount}`);
})

// Footernavigation {
for (let footernavEntry of document.querySelectorAll('.footer__navigationcontainer li.parent > a, .footer__navigationcontainer li.parent > span')) {
  footernavEntry.addEventListener('click', () => {
    footernavEntry.classList.toggle('opened')
  })
}

// Check if scrolled to change styling for fixed elements

window.addEventListener('scroll', () => {
  if(window.scrollY > 0) {
    document.querySelector('body').classList.add('body--scrolled')
  } else {
    document.querySelector('body').classList.remove('body--scrolled')
  }
})

// Hide Navi on Scroll-Down

window.onscroll = function(e) {
  let scrollY = window.pageYOffset || document.documentElement.scrollTop;
  let header = document.querySelector('.headerbar');
  if (window.innerWidth >= 992) {
    scrollY >= this.lastScroll && scrollY != 0
      ? header.style.transform = 'translateY(calc(-100% - 90px))'
      : header.style.transform = 'translateY(0%)'

    this.lastScroll = scrollY ;
  } else {
    header.style.transform = 'initial'
  }
}


// Offcanvas Behaviour

for (let accordionItem of document.querySelectorAll('.accordion__item')) {
  for (let itemToggle of accordionItem.querySelectorAll('[data-toggle="true"]')) {
    itemToggle.addEventListener('click', () => {
      accordionItem.classList.toggle('opened')
    })
  }
}

let setMenuLevel = (level) => {
  let menu = document.querySelector('.headerbar__navigation > ul');

  for (let clickedItem of document.querySelectorAll('.headerbar__navigation li.parent.clicked')) {
    clickedItem.classList.remove('clicked')
  }

  menu.setAttribute('class', menu.getAttribute('class').replace(/(nav--level\d)/, ''))
  menu.classList.add('nav--level' + level)

}

// Main menu behavior
for (let menuItem of document.querySelectorAll('.headerbar__navigation li.parent > a, .headerbar__navigation li.parent > span')) {
  menuItem.addEventListener('click', (e) => {
    if (window.innerWidth < 992) {
      const item = e.target;

      // Prevent link opening
      e.preventDefault()

      const parentClass = item.parentElement.parentElement.getAttribute('class')
      const parentLevel = parentClass.match(/list--level(\d)/)[1]

      setMenuLevel(parseInt(parentLevel) + 1)

      item.parentElement.classList.add('clicked')

      if (item.parentElement.parentElement.parentElement.classList.contains('parent')) {
        item.parentElement.parentElement.parentElement.classList.add('clicked')
      }
    }
  });
}

for (let menuItem of document.querySelectorAll('.headerbar__navigation button.mainnav__backlink')) {
  menuItem.addEventListener('click', (e) => {
    if (window.innerWidth < 992) {
      e.preventDefault()

      const parentClass = e.target.parentElement.parentElement.getAttribute('class')
      const parentLevel = parentClass.match(/list--level(\d)/)[1]

      setMenuLevel(parseInt(parentLevel) - 1)

      e.target.parentElement.parentElement.parentElement.classList.add('clicked')

      if (e.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.contains('parent')) {
        e.target.parentElement.parentElement.parentElement.parentElement.parentElement.classList.add('clicked')
      }
    }
  });
}

// Scale Animation for Page-Intro

if(document.querySelector('.fullscreenheader__media ')) {
  window.addEventListener('scroll', () => {
    let scaleValue = 1 + window.scrollY / window.innerHeight * .3
    document.querySelector('.fullscreenheader__media ').style.transform = 'scale(' + scaleValue + ')'
  })
}

// Scale VideoOnScroll

let scaleVideoOnScrollContainer = (container) => {
  if (window.innerWidth >= 1600) {
    container.style.transform = 'scale(' + (window.innerWidth - 320) / window.innerWidth  + ')'
  }
  else if (window.innerWidth >= 1320) {
    container.style.transform = 'scale(' + (window.innerWidth - 160) / window.innerWidth  + ')'
  } else {
    container.style.transform = 'scale(' + (window.innerWidth - 48) / window.innerWidth  + ')'
  }
}

for (let videoContainer of document.querySelectorAll('.videocontentonscroll video')) {
  window.addEventListener('DOMContentLoaded', scaleVideoOnScrollContainer(videoContainer));
  window.addEventListener('resize', scaleVideoOnScrollContainer(videoContainer));
}

// Article Latest Integrations

let displayIntegrationGridItems = (items, filter) => {
  if(filter.length === 0) {
    for (let item of items) {
      item.style.display = 'block';
    }
    return
  }
  for (let item of items) {
    if(item.dataset.cat.toLowerCase() ==  filter.toLowerCase()) {
      item.style.display = 'block';
    } else {
      item.style.display = 'none'
    }
  }
}

if (document.querySelector('.mod-articleslatest--integrations')) {
  for (let integrationGrid of document.querySelectorAll('.mod-articleslatest--integrations')) {

    // Set active IntegrationCat
    let activeIntegrationCat = ''

    // Change active IntegrationCat on Click
    for (let navItem of integrationGrid.querySelectorAll('.mod-articleslatest--integrations__nav li')) {
      navItem.addEventListener('click', () => {
        // Current active Category is clicked category
        if (navItem.classList.contains('active')) {
          return;
        }
        // Set New active Category
        for (let navItem of integrationGrid.querySelectorAll('.mod-articleslatest--integrations__nav li')) {
          navItem.classList.remove('active')
        }
        navItem.classList.add('active');
        activeIntegrationCat = navItem.dataset.value;
        // Filter Items
        displayIntegrationGridItems(integrationGrid.querySelectorAll('.mod-articleslatest--integrations__item'), activeIntegrationCat);

      })
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  const urlParams = new URLSearchParams(window.location.search);
  const storeRow = {}

  utmParams.forEach(param => {
    if (urlParams.has(param)) {
      const value = urlParams.get(param);
      storeRow[param] = value
    }
  });

  if (!Object.keys(storeRow).length) {
    return
  }

  localStorage.setItem('utmstore', JSON.stringify(storeRow))
});

// AOS - Initialize AOS Library
window.addEventListener('DOMContentLoaded', () => {
  let initializeAOS = () => {
    AOS.init({
      duration: 500,
      easing: 'ease-in-out-quart',
      once: true,
      anchorPlacement: 'top-bottom'
    });
  }
  window.setTimeout(initializeAOS,25);
})

